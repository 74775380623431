import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.hasPortrait)?_c(VContainer,{staticClass:"pa-0"},[_c(VRow,{staticClass:"video-tabs-container"},[_c(VCol,{staticClass:"px-6",attrs:{"cols":"auto"}},[_c(VTabs,{staticClass:"video-tabs",attrs:{"background-color":"main-background","hide-slider":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.label,staticClass:"text-capitalize video-tab px-5",class:{
                        'video-mode-active': _vm.isActive(tab)
                    },attrs:{"to":_vm.fullify(tab.to)}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "+_vm._s(tab.label)+" ")],1)}),1)],1)],1),_c('router-view',_vm._g({key:_vm.componentKey},_vm.$listeners))],1):_c('router-view',_vm._g({},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container v-if="hasPortrait" class="pa-0">
        <v-row class="video-tabs-container">
            <v-col class="px-6" cols="auto">
                <v-tabs
                    v-model="activeTab"
                    background-color="main-background"
                    class="video-tabs"
                    hide-slider
                >
                    <v-tab
                        v-for="tab in tabs"
                        :key="tab.label"
                        class="text-capitalize video-tab px-5"
                        :class="{
                            'video-mode-active': isActive(tab)
                        }"
                        :to="fullify(tab.to)"
                    >
                        <v-icon small left>{{ tab.icon }}</v-icon>
                        {{ tab.label }}
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <router-view :key="componentKey" v-on="$listeners" />
    </v-container>
    <router-view v-else v-on="$listeners" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { AmpModules } from '@/types/Announcement';

type VideoTab = {
    label: string;
    icon: string;
    to: string;
};

@Component
export default class VideoTabs extends Vue {
    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    activeTab = this.isPortrait ? 1 : 0;

    get isPortrait() {
        return this.$route.params.mode === 'portrait';
    }

    get componentKey() {
        return this.isPortrait ? 'portrait' : 'landscape';
    }

    get tabs(): VideoTab[] {
        return [
            {
                label: 'Landscape',
                icon: 'image',
                to: 'video'
            },
            {
                label: 'Portrait',
                icon: 'image-portrait',
                to: 'video/portrait'
            }
        ];
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get hasPortrait() {
        return !!this.modules.portrait_video_pr_id;
    }

    isActive(tab: VideoTab) {
        if (this.isPortrait) {
            return tab.to === 'video/portrait';
        }

        return tab.to === 'video';
    }

    fullify(to: string) {
        return `/announcements/${this.$route.params.action}/${this.announcementId}/${to}`;
    }
}
</script>

<style lang="scss" scoped>
.video-tabs-container::v-deep {
    margin-bottom: -24px;

    .video-tabs {
        z-index: 1;
        position: relative;

        .v-tabs-bar__content {
            padding-left: 24px;
        }

        .video-tab {
            letter-spacing: normal;

            &.v-tab--active {
                color: $secondary-color;

                &.video-mode-active {
                    color: $primary-color;
                    background-color: white;
                    box-shadow:
                        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 5px 0px rgba(0, 0, 0, 0.12);

                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;

                    &:before {
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }
                }
            }
        }
    }
}
</style>
